import { Button } from "primereact/button";
import avatar from "../../../assets/images/avatar-image.png";


export function getUserName(name) {
  let userName = "";
  if (name) {
    userName = name.split('@');
    return `${userName[0].charAt(0)} ${userName[0].charAt(userName[0].length - 1)}`;
  }
  return userName
}

export function getUserImage(image) {
  let userImage = "";
  if (image && image !== "string") {
    return image;
  }
  return userImage;
}

export const imageSrc = (rowData) => {
  let src = avatar
  if (rowData.userProfileImage) {
    src = rowData.userProfileImage;
  }
  return src;
}

export const templatePaginatorLogInfo = {
  layout:
    "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
  PrevPageLink: (options) => (
    <Button
      type="button"
      icon="pi pi-angle-left"
      className={options.className}
      onClick={options.onClick}
      disabled={options.disabled}
    />
  ),
  NextPageLink: (options) => (
    <Button
      type="button"
      icon="pi pi-angle-right"
      className={options.className}
      onClick={options.onClick}
      disabled={options.disabled}
    />
  ),
};