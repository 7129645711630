export const formatReadableDate = (dateString, locale = "en-GB") => {
    if(!dateString) return '-'
    const date = new Date(dateString);
  
    return date.toLocaleString(locale, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  }