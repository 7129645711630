import React from "react";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useQuery } from "react-query";
import { GetProcessesData } from "../../services/processes-service";
import { noRefetchOnWindowFocus } from "../../services/common/useQuery-config";
import settingsBlack from "../../assets/images/icon/settings-black.svg";
import valid from "../../assets/images/icon/valid.svg";
import errors from "../../assets/images/icon/errors.svg";
import lastRun from "../../assets/images/icon/last-run.svg";
import schedule from "../../assets/images/icon/schedule.svg";
import cronstrue from "cronstrue";
import "./Processes.scss";
import { formatReadableDate } from "../../utils/dateHelper";

const Processes = () => {
  const history = useHistory();

  const { data: processesDetails, isLoading } = useQuery(
    ["processDetails"],
    GetProcessesData,
    noRefetchOnWindowFocus
  );

  const processTemplate = (rowData) => {
    const { lastImport, importName } = rowData;

    return (
      <div onClick={openProcess(rowData)} className="process-template cursor" role='presentation'>
        <div className="process-content">
          <div className="import-name">
            <p className="bold-text">{importName}</p>
          </div>
          <div className="process-details">
            <div className="details-wrapper">
              <div className="detail-item">
                <img
                  src={
                    lastImport.errors.totalErrorsCount === 0 ? valid : errors
                  }
                  alt={
                    lastImport.errors.totalErrorsCount === 0
                      ? "Valid"
                      : "Errors"
                  }
                  className="status-icon"
                />
                <span className="detail-label">Errors:</span>
                <span className="detail-value">
                  {lastImport.errors.totalErrorsCount}
                </span>
              </div>
              <div className="detail-item">
                <img src={schedule} alt="Schedule" className="status-icon" />
                <span className="detail-label">Schedule:</span>
                <span className="detail-value">
                  {cronstrue.toString(rowData.cronExpression)}
                </span>
              </div>
              <div className="detail-item">
                <img src={lastRun} alt="Last Run" className="status-icon" />
                <span className="detail-label">Last Import:</span>
                <span className="detail-value">
                  {lastImport.fileName}
                </span>
              </div>
              {lastImport.dateUploaded && (
                <div className="detail-item">
                  <img src={lastRun} alt="Last Run" className="status-icon" />
                  <span className="detail-label">Import Date:</span>
                  <span className="detail-value">
                    {formatReadableDate(lastImport.dateUploaded)}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const typeTemplate = (rowData) => {
    const processType = JSON.parse(rowData.configJSON).details.name;
    return (
      <div className="padding-cursor type-wrap">
        <span className="p-column-title">Type</span>
        <div className="col-12">{processType}</div>
      </div>
    );
  };

  const openProcess = (rowData) => () => {
    history.push(`/process/${rowData.id}`);
  };

  const header = (
    <div className="table-header">
      <div className="col-12 no-padding-left-right processes-logo">
        <span>
          <img
            src={settingsBlack}
            alt="Processes Page"
            style={{ width: "1.25em", height: "1.25em", display: "block" }}
          />
        </span>
        <span>Processes</span>
      </div>
    </div>
  );

  return (
    <div className="processes">
      {!isLoading && (
        <div className="display wrap-center">
          <div className="processes-wrapper">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <>
                {processesDetails?.length === 0 ? (
                  <>
                    <DataTable
                      value={[]}
                      header={header}
                      className="datatable-gridlines"
                    />
                    {"No data found"}
                  </>
                ) : (
                  <div
                    className={`${
                      processesDetails?.length > 0
                        ? "processes-data-table"
                        : "no-data-msg"
                    }`}
                  >
                    <DataTable
                      value={processesDetails}
                      header={header}
                      sortMode="multiple"
                      className="datatable-gridlines"
                      emptyMessage={"No data found"}
                    >
                      <Column
                        field="importName"
                        header={processesDetails?.length > 0 ? "Process" : ""}
                        headerClassName="table-header-style"
                        body={processTemplate}
                        style={{ width: "40%" }}
                        className={""}
                        sortable
                      />
                      <Column
                        field="importName"
                        header={processesDetails?.length > 0 ? "Type" : ""}
                        headerClassName="table-header-style"
                        body={typeTemplate}
                        style={{ width: "30%", textAlign: "left" }}
                        className={""}
                        sortable
                        filter
                      />
                    </DataTable>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default Processes;
