import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useQuery } from "react-query";
import {
  GetProcessDetailData,
  GetProcessInfoData,
  UploadDataFile,
} from "../../services/processes-service";
import { noRefetchOnWindowFocus } from "../../services/common/useQuery-config";
import settingsBlack from "../../assets/images/icon/settings-black.svg";
import valid from "../../assets/images/icon/valid.svg";
import errors from "../../assets/images/icon/errors.svg";
import "./ProcessDetail.scss";
import { FileUpload } from "primereact/fileupload";
import { Dialog } from "primereact/dialog";
import RowErrorDialog from "../row-error-dialog/RowErrorDialog";
import { templatePaginatorLogInfo } from "../common/helpers/Helper";
import { formatReadableDate } from "../../utils/dateHelper";

const ProcessDetail = () => {
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(10)
  const [showErrorsModal, setShowErrorsModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [uploadSucceeded, setUploadSucceeded] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const url = window.location.href;
  const urlId = url.split("/").pop();
  let uploadId = useRef([]);

  const { isLoading: processDataLoading, data: processData } = useQuery(
    ["processInfoData", parseInt(urlId), first, rows],
    GetProcessInfoData
  );

  const { data: processDetails } = useQuery(
    ["processDetailData", parseInt(urlId)],
    GetProcessDetailData,
    noRefetchOnWindowFocus
  );

  useEffect(() => {
   if(processDetails) {
    uploadId.current = `uploadBtn_${processDetails.id}`;
   }
  }, [processDetails])
  

  const getIsProcessedIcon = (isProcessed, error) => {
    return !isProcessed || error > 0 ? (
      <img src={errors} alt="Errors" />
    ) : (
      <img src={valid} alt="Valid" />
    );
  };

  const onPageChange = (e) => {
    setFirst(e.first)
    setRows(e.rows)
  }
  const onHideErrorDialog = () => {
    setShowErrorsModal(false);
    setSelectedRowData(null);
  };

  const importsTemplate = (rowData, colData) => {
    return (
      <div className="padding-cursor type-wrap">
        <div
          className="col-12"
          onClick={() => handleErrorClick(rowData)}
          style={{ cursor: rowData.errors > 0 ? "pointer" : "default" }}
          role="presentation"
        >
          {colData.field === "isProcessed"
            ? getIsProcessedIcon(rowData[colData.field], rowData.errors)
            : rowData[colData.field] ?? "-"}
        </div>
      </div>
    );
  };

  const uploadedOnTemplate = (rowData, colData) => {
    return (
      <div className="padding-cursor type-wrap">
        <div
          className="col-12"
          onClick={() => handleErrorClick(rowData)}
          style={{ cursor: rowData.errors > 0 ? "pointer" : "default" }}
          role="presentation"
        >
        {formatReadableDate(rowData[colData.field])}
        </div>
      </div>
    );
  };

  const handleErrorClick = (rowData) => {
    if (rowData.errorDetails?.length > 0) {
      setSelectedRowData(rowData);
      setShowErrorsModal(true);
    }
  };

  const uploadHandler = (event) => {
    let importConfigId = event.options.props.id.split("_")[1];
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      UploadDataFile(e.target.result, event.files[0], importConfigId)
        .then((result) => {
          if (result) {
            setUploadSucceeded(true);
          } else {
            setUploadFailed(true);
          }
        })
        .catch((error) => {
          console.log(error.message);
          setUploadFailed(true);
        });
    };

    fileReader.readAsDataURL(event.files[0]);

    event.options.clear();
  };

  return (
    <div className="process-detail">
      <div className="display wrap-center">
        <div className="process-wrapper">
          {processDetails ? (
            <div className="process-details-wrap">
              <div className="col-12 no-padding-left-right processes-logo">
                <span>
                  <img
                    src={settingsBlack}
                    alt="Processes Page"
                    style={{
                      width: "1.25em",
                      height: "1.25em",
                      display: "block",
                    }}
                  />
                </span>
                <span>{processDetails.importName}</span>
              </div>
              <div className="process-content-wrap">
                <div>
                  <p>
                    <span>
                      <FileUpload
                        className="upload-config-btn"
                        id={uploadId.current}
                        mode="basic"
                        name="dataBtn"
                        accept=".json,.csv"
                        customUpload={true}
                        auto={true}
                        chooseOptions={{ label: "Upload file" }}
                        uploadHandler={uploadHandler}
                      />
                    </span>
                  </p>
                </div>
              </div>
              <Dialog
                header="File Upload"
                visible={uploadSucceeded}
                style={{ width: "50vw" }}
                onHide={() => {
                  if (!uploadSucceeded) {
                    return;
                  }
                  setUploadSucceeded(false);
                }}
              >
                <p className="m-0">File uploaded successfully</p>
              </Dialog>
              <Dialog
                header="File Upload"
                visible={uploadFailed}
                style={{ width: "50vw" }}
                onHide={() => {
                  if (!uploadFailed) {
                    return;
                  }
                  setUploadFailed(false);
                }}
              >
                <p className="m-0">File was not uploaded</p>
              </Dialog>
            </div>
          ) : null}
            <div className="data-table">
              <DataTable
                rowsPerPageOptions={[10, 20, 50]}
                paginator={processData?.totalItems > 10}
                paginatorTemplate={templatePaginatorLogInfo}
                rows={rows}
                lazy
                onPage={e => onPageChange(e)}
                first={first}
                value={processData?.items}
                sortMode="multiple"
                className="datatable-gridlines"
                emptyMessage={"No successful imports"}
                totalRecords={processData?.totalItems}
                loading={processDataLoading}
              >
                <Column
                  bodyField="clientName"
                  field="fileName"
                  body={importsTemplate}
                  header="File Name"
                  headerClassName="table-header-style"
                  style={{ width: "20%" }}
                  sortable
                />
                <Column
                  bodyField="isProcessed"
                  field="isProcessed"
                  body={importsTemplate}
                  header=""
                  headerClassName="table-header-style"
                  style={{ width: "10%" }}
                  sortable
                />
                <Column
                  bodyField="uploadedAt"
                  field="uploadedAt"
                  body={uploadedOnTemplate}
                  header="Uploaded on"
                  headerClassName="table-header-style"
                  style={{ width: "20%" }}
                  sortable
                />
                <Column
                  bodyField="rowsInFile"
                  field="rowsInFile"
                  body={importsTemplate}
                  header="Rows in file"
                  headerClassName="table-header-style"
                  style={{ width: "20%" }}
                  sortable
                />
                <Column
                  bodyField="errors"
                  field="errors"
                  body={importsTemplate}
                  header="Errors"
                  headerClassName="table-header-style"
                  style={{ width: "20%" }}
                  sortable
                />
              </DataTable>
            </div>
        </div>
      </div>
      {selectedRowData && (
        <RowErrorDialog
          errors={selectedRowData.errorDetails}
          visible={showErrorsModal}
          onHide={onHideErrorDialog}
          processName={processDetails.importName}
          fileName={selectedRowData.fileName}
        />
      )}
    </div>
  );
};
export default ProcessDetail;