import React, {useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import {Sidebar} from 'primereact/sidebar';
import { useAuth0 } from "@auth0/auth0-react";
import './Header.scss';
import logo from '../../../assets/images/header/siera-logo-sand.svg';
import settingGrey from '../../../assets/images/icon/setting.grey.svg';
import settingWhite from '../../../assets/images/icon/settings.white.svg';
import settingsClose from '../../../assets/images/icon/settings-close.svg';
import logoutIcon from '../../../assets/images/icon/logout-arrow.svg';
import logout_box_gray from "../../../assets/images/icon/footerIcons/logout-box-gray.svg";

const Header = () => {
  const history                             = useHistory();
  const { logout, isAuthenticated }         = useAuth0();
  const [visibleRight, setVisibleRight]     = useState(false);
  const CLASS_HEADER_HIGHLIGHT_CLICKABLE_OPACITY='header-highlight-clickable-opacity';

  const openHome = () => {
    if (isAuthenticated) {
      history.push('/processes');
    }
  }

  useEffect(() => {
    if (visibleRight) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  })

  const LogoutUser = () => {
    logout({ logoutParams: { returnTo: window.location.origin }}); 
    setVisibleRight(false);
    sessionStorage.removeItem('userName');
    history.push('/');
  }

  return (
      <div className="header-home">
        <div onClick={openHome}>
          <img src={logo} alt="siera" className={`logo ${CLASS_HEADER_HIGHLIGHT_CLICKABLE_OPACITY}`}/>
        </div>
        <div className={`header_nav ${isAuthenticated ? "" : "hide"}`}>
          <span className="user-margin-left">
            <div className="vl"/>
          </span>

          <span className="user user-margin-left">
            <span>
              <Sidebar visible={visibleRight} position="right" baseZIndex={1000000}
                       onHide={() => setVisibleRight(false)}
                       className="side-bar" showCloseIcon={false}>
                <div className="sidebar-header">
                  <div className="settings">
                    <span className="settings-icon">
                      <img src={settingWhite} alt="Settings"/>
                    </span>
                    <span className="settings-title">Settings</span>
                  </div>
                  <div className="logout" onClick={LogoutUser}>
                    <span className={`logout-title ${CLASS_HEADER_HIGHLIGHT_CLICKABLE_OPACITY}`}>Log out</span>
                    <span className={`logout-icon ${CLASS_HEADER_HIGHLIGHT_CLICKABLE_OPACITY}`}>
                      <img src={logout_box_gray} className="logout-box" alt="Log out" />
                      <img src={logoutIcon} alt="Logout" className="logout-arrow"/>
                    </span>
                  </div>
                </div>
              </Sidebar>
            </span>
            <span className="icon-setting">
              <img 
                className={`${CLASS_HEADER_HIGHLIGHT_CLICKABLE_OPACITY} ${visibleRight ? 'header-icon-close' : 'header-icon-setting'}`} 
                src={visibleRight ? settingsClose : settingGrey} 
                alt="Settings"
                   onClick={() => setVisibleRight(true)}/>
            </span>
          </span>
        </div>
      </div>
  )
}

export default Header;
